/**
 * DrvContent is shared Component which is used on common page of this Website.
 * Contains 4 type of contents: text and image, image and text, text only and image only.
 *
 * To use this component you need to specify array with types of content ex. ['text', 'image'],
 * array with html content, text-color and background-color.
 **/

import React from "react";

import {Container, Content} from './DrvCardNews.styles';
import { DrvButton } from "../DrvButton/DrvButton";
import { NewsImage } from "../DrvNewsCard/DrvNewsCard.styles";
const DrvCardNews = ({type, data, title, description}) => {
    return (
        <Container className='container mx-auto flex flex-col justify-center gap-x-4'>
            <Content type={type[0]} className='flex flex-col flex-1 justify-center'>
                <div className='drv-subtitle'>
                    {title}
                </div>
                <div className='drv-description'>
                    {description}
                </div>
            </Content>
            <Content type={type[0]} className='flex flex-1 justify-between gap-[2rem] flex-wrap'>
                {
                     data.map((item, index) => (
                        <div key={index} className="flex-1 min-w-[360px] max-w-[400px] h-[420px] bg-white rounded-[15px] p-[15px] shadow-[0px_1px_5px_1px_rgba(0,0,0,0.1)] items-center flex flex-col gap-2 justify-between">
                            <div className="h-[230px] w-full bg-green rounded-t-[15px]">
                                <NewsImage src={item.image} alt={item.alt} className="h-full" />
                            </div>
                            <div className="text-center"> 
                                {item.description}
                            </div>
                            <div>
                                <DrvButton href={item.externalLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="w-fit"
                                        background="transparent"
                                        color="var(--primary-color)"
                                        hoverbackground="var(--primary-color)"
                                        hoverborder="var(--primary-color)"
                                        hovercolor="#fff"
                                >
                                    READ MORE
                                </DrvButton>
                            </div>
                        </div>
                    ))
                }
            </Content>

        </Container>
    );
}

export default DrvCardNews;
