import styled from "styled-components"

export const Container = styled.div`
  color: var(--text-color);
  background-color: ${({background}) => background};
`

export const Content = styled.div`
  padding: ${({ type }) => (type === 'text' ? '40px 0px' : '0px 0px')};
`;


