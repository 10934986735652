import styled from "styled-components";

export const VideoContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow:hidden;
  height: 780px;
  z-index: 1;
  
  & video {
    position: absolute;
    top: -120px;
    width: ${({width}) => width}px;
    height: ${({height}) => height}px;
    object-fit: cover;
  }
  
  & a {
    margin: 20px 0px 0px 50px;
  }
`

export const Cover = styled.img`
  position: absolute;
  width: 1250px;
  height: 890px;
  object-fit: cover;
`

export const TextContainer = styled.div`
  padding-top: 100px; 
  padding-left: 0px;

  @media (min-width: 970px) {
    padding-left: 150px;
  }

  & .fade-in {
    opacity: 1;
    animation-name: fade-in;
    animation-timing-function: ease-in;
    animation-duration: 1.7s;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const VideoText = styled.div`
  line-height: 44px;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 35px;
  color: #ffffff;
  white-space: nowrap;
  padding-left: 50px;
  animation-name: fade-in-bottom;
  animation-duration: 1s;
  text-shadow: 0px 0px 9px black;
  text-wrap:auto;
  text-wrap: auto;

  @media (min-width: 640px) {
    line-height: 57px;
    font-size: 48px;
  }
  
  & div {
    font-weight: 600;
  }

  @keyframes fade-in-bottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    
    to { 
      opacity: 1 
    }
  }
`
export const VideoDescription = styled.div`
  line-height: 44px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  padding-left: 50px;
  animation-name: fade-in-bottom;
  animation-duration: 1s;
  text-shadow: 0px 0px 3px black;
  text-wrap: auto;
  

  @media (min-width: 640px) {
    font-size: 16px;
  }
  
  & div {
    font-weight: 600;
  }

  @keyframes fade-in-bottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    
    to { 
      opacity: 1 
    }
  }
`
