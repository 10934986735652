import React, {useCallback, useEffect, useState} from 'react';
import { TaberContent, TaberContainer, TaberImages, CardsPlace,TaberHeader, TaberTitle, TaberText } from './DrvVerticalTaber.styles';
import DrvSquareCard from "../DrvHoverCard/DrvSquareCardUI";

const DrvVerticalTaber = ({data}) => {
    const [active, setActive] = useState(0);

    const changeTab = useCallback((index) => {
        setActive(index)
    }, [])

    useEffect(() =>{
        if(active === 4){
            setActive(0)
        }
        const interval = setInterval(() => {
                setActive(active => active + 1);
        },5000);
        return () => clearInterval(interval)
    },[active])

    return(
        <TaberContent className="flex justify-center items-center">
            <TaberContainer className="container flex flex-col items-center">
                <TaberHeader className={'flex mb-5 '}>Transform Your Delivery Operations</TaberHeader>
                <div className='flex '>
                    <CardsPlace className='hidden lg:flex'>
                        {data.map((item, index) => (
                            <DrvSquareCard click={() => changeTab(index)} activeTab={index === active} title={item.title} text={item.content} key={index} />
                        ))}
                    </CardsPlace>
                    <TaberImages >
                        {data.map((item, index) =>(
                            <div className={`${index === active ? 'block' : 'hidden' } `} key={index} >
                                <TaberTitle className={'flex lg:hidden justify-center'}>
                                    {item.title}
                                </TaberTitle>
                                <TaberText className={'flex lg:hidden justify-center'}>
                                    {item.content}
                                </TaberText>
                                <img src={item.data.image} key={index} alt="Drivosity application mockups" />
                            </div>
                        ))}
                    </TaberImages>
                </div>
            </TaberContainer>
        </TaberContent>
    )
};
export default DrvVerticalTaber
