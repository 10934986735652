import React, {useEffect, useMemo, useState} from "react";

import {VideoContent, Cover, VideoText, TextContainer, VideoDescription} from "./IntroVideo.styles";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";

import Intro from "../../../images/Home/IntroOptimized.mp4";
import CoverPhoto from "../../../images/Home/Cover.jpg";

const IntroVideo = () => {
    const [dimensions, setDimensions] = useState({
        width: 3840,
        height: 2160
    });
    const [play, setPlay] = useState(true);

    useMemo(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', () => {
                setDimensions(previous => {
                    const ratio = window.innerWidth / previous.width;

                    if (ratio < 1) {
                        return previous;
                    }

                    return {
                        width:  window.innerWidth < 1920 ? 1920 : window.innerWidth,
                        height: window.innerWidth < 1920 ? 1080 : 2160 * (window.innerWidth / 3840)
                    }
                })
            });
        }
    }, [setDimensions]);

    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth < 1250) {
                setPlay(false);
            }
        }, 100);

        setDimensions({
            width:  window.innerWidth < 1920 ? 1920 : window.innerWidth,
            height: window.innerWidth < 1920 ? 1080 : 2160 * (window.innerWidth / 3840)
        });
    }, [setDimensions]);

    return (
        <VideoContent height={dimensions.height} width={dimensions.width}>
            <video autoPlay={play} muted className='hidden xl:block'>
                <source src={Intro} type="video/mp4" />
            </video>

            <Cover src={CoverPhoto} className='block xl:hidden' />

            <TextContainer className='container mx-auto z-10 flex flex-col justify-center'>
                <VideoText>
                    Drive Success, Deliver Excellence
                </VideoText>
                <VideoDescription>
                    Welcome to Drivosity—the innovative solution that empowers your first-party delivery operations with safety, efficiency, recognition, and real-time insights. 
                </VideoDescription>
                <DrvButton to="/request-demo"
                           className="w-fit fade-in"
                           background='rgba(12, 0, 0, 0.43)'
                           bordercolor='rgba(255, 255, 255, 0.35)'
                           color='#ffffff'
                           hoverbackground='var(--primary-color)'
                           hovercolor='#ffffff'
                           hoverborder='var(--primary-color)'
                >
                    Request a demo
                </DrvButton>
            </TextContainer>
        </VideoContent>
    );
}

export default IntroVideo;
